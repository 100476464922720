import React, {
  useState,
  createContext,
  useContext,
  useMemo,
  useEffect
} from "react";
import { useIpAdminApi } from "../api";

export interface ILoginContainerProps {
  require?: "login" | "admin";
}

export interface ILoginState {
  isLoggedIn: boolean;
  isAdmin: boolean;
  login(): void;
  logout(): void;
}

const defaultLoginState: ILoginState = {
  isLoggedIn: false,
  isAdmin: false,
  login() {
    throw new Error(
      "Attempt to call useLogin().login() outside of LoginContainer."
    );
  },
  logout() {
    throw new Error(
      "Attempt to call useLogin().logout() outside of LoginContainer."
    );
  }
};

const LoginContext = createContext<ILoginState>(defaultLoginState);

export function useLogin(): ILoginState {
  return useContext(LoginContext);
}

export const LoginContainer: React.FC<ILoginContainerProps> = ({
  require,
  children
}) => {
  const [loginData, setLoginData] = useState({
    isLoggedIn: false,
    isAdmin: false,
    didCheck: false
  });

  const { apiUrl } = useIpAdminApi();

  const loginState = useMemo(
    () => ({
      isLoggedIn: loginData.isLoggedIn,
      isAdmin: loginData.isAdmin,
      login() {
        window.location.href = `${apiUrl}/authorize?redirect_uri=${encodeURIComponent(
          window.location.href
        )}`;
      },
      logout() {
        window.location.href = `${apiUrl}/endsession?redirect_uri=${encodeURIComponent(
          window.location.href.replace(/\/logout/, "")
        )}`;
      }
    }),
    [loginData, apiUrl]
  );

  const { didCheck } = loginData;
  useEffect(() => {
    // Check our login status.
    (async () => {
      const response = await fetch(`${apiUrl}/whoami`, {
        credentials: "include"
      });
      const whoami = await response.json();
      const { loggedIn } = whoami;
      const isAdmin = loggedIn && whoami.tokenData && whoami.tokenData.isAdmin;
      setLoginData(data =>
        data.didCheck
          ? data
          : {
              isLoggedIn: loggedIn,
              isAdmin,
              didCheck: true
            }
      );
    })();
  }, [apiUrl]);
  if (!didCheck) {
    return null;
  }

  const { isLoggedIn, isAdmin } = loginState;
  if ((require === "admin" || require === "login") && !isLoggedIn) {
    loginState.login();
    return null;
  }
  if (require === "admin" && !isAdmin) {
    loginState.logout();
    return null;
  }

  return (
    <LoginContext.Provider value={loginState}>{children}</LoginContext.Provider>
  );
};
