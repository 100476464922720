import { createElement, useMemo, ReactElement } from "react";
import ListIcon from "@material-ui/icons/List";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ExploreIcon from "@material-ui/icons/Explore";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

export interface IMenuItem {
  path: string;
  title: string;
  available: boolean;
  dividerAbove?: boolean;
  icon?: ReactElement;
}

export function useMainMenu(): Array<IMenuItem> {
  return useMemo(
    () => [
      {
        path: "/edit",
        title: "Beheer",
        available: true,
        icon: createElement(ListIcon)
      },
      {
        path: "/ip-check",
        title: "Check IP",
        available: true,
        icon: createElement(ExploreIcon)
      },
      {
        path: "/import-export",
        title: "Import/export",
        available: true,
        icon: createElement(ImportExportIcon)
      },
      {
        path: "/logout",
        title: "Uitloggen",
        available: true,
        dividerAbove: true,
        icon: createElement(ExitToAppIcon)
      }
    ],
    []
  );
}
