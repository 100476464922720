import ApolloClient from "apollo-boost";
import { appConfig } from "../config/config";
import { createContext, useContext, createElement, useMemo } from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { InMemoryCache, defaultDataIdFromObject } from "apollo-cache-inmemory";

export interface IIpAdminApi {
  apiUrl: string;
  graphQLClient: ApolloClient<unknown>;
}

const defaultApiConfig = appConfig();

const graphQLCache = new InMemoryCache({
  dataIdFromObject: object => {
    switch (object.__typename) {
      case "Organization":
        return null;
      default:
        return defaultDataIdFromObject(object);
    }
  }
});

function createIpAdminApi(apiUrl: string): IIpAdminApi {
  return {
    apiUrl,
    graphQLClient: new ApolloClient({
      uri: `${apiUrl}/graphql`,
      cache: graphQLCache,
      credentials: "include"
    })
  };
}

const IpAdminContext = createContext(createIpAdminApi(defaultApiConfig.apiUrl));

export function useIpAdminApi(): IIpAdminApi {
  return useContext(IpAdminContext);
}

interface IIpAdminApiProps {
  apiUrl?: string;
}

export const IpAdminApiProvider: React.FC<IIpAdminApiProps> = ({
  children,
  apiUrl
}) => {
  const api = useMemo(
    () => createIpAdminApi(apiUrl || defaultApiConfig.apiUrl),
    [apiUrl]
  );
  return createElement(
    IpAdminContext.Provider,
    {
      value: api
    },
    createElement(ApolloProvider, {
      client: api.graphQLClient,
      children
    })
  );
};
