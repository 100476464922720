import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppMenu } from "./AppMenu";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    }
  })
);

export const AppLayout: React.FC<{}> = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppMenu />
      {children}
    </div>
  );
};
