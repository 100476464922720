export interface AppConfig {
  apiUrl: string;
}

export function appConfig(): AppConfig {
  const config: AppConfig | undefined = (window as any).appConfig;
  if (!config) {
    throw new Error(
      "No app configuration found. Include /config.js into your build."
    );
  }
  return config;
}
