import React, { useState, useCallback, useMemo } from "react";
import {
  createStyles,
  makeStyles,
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Theme,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { Route, Switch, useHistory } from "react-router-dom";
import { useMainMenu } from "../navigation/useMainMenu";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1
    },
    drawerMenu: {
      minWidth: 250
    }
  })
);

export const AppMenu = React.memo(() => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const classes = useStyles();
  const menuItems = useMainMenu();
  const history = useHistory();

  const onMenuOpen = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setMenuOpen(open => !open);
  }, []);
  const onMenuClose = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setMenuOpen(false);
  }, []);
  const menuItemsWithHandlers = useMemo(
    () =>
      menuItems.map(item => ({
        ...item,
        onClick: () => {
          history.push(item.path);
          setMenuOpen(false);
        }
      })),
    [menuItems, history]
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.menuButton}
            onClick={onMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            <Switch>
              {menuItems.map(({ path, title }) => (
                <Route key={path} path={path} exact={path === "/"}>
                  {title}
                </Route>
              ))}
            </Switch>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer open={menuOpen} onClose={onMenuClose}>
        <div className={classes.drawerMenu}>
          <List>
            <ListItem>
              <ListItemText>
                <Typography variant="h6">Sdu IP admin</Typography>
              </ListItemText>
            </ListItem>
            {menuItemsWithHandlers
              .filter(item => item.available)
              .map(({ path, title, icon, onClick, dividerAbove }) => (
                <React.Fragment key={path}>
                  {dividerAbove && <Divider />}
                  <ListItem button onClick={onClick}>
                    <ListItemIcon>{icon || <ArrowRightIcon />}</ListItemIcon>
                    <ListItemText>{title}</ListItemText>
                  </ListItem>
                </React.Fragment>
              ))}
          </List>
        </div>
      </Drawer>
    </>
  );
});
