import React, { Suspense } from "react";
import { AppThemeProvider } from "./theme/AppThemeProvider";
import { AppLayout } from "./layout/AppLayout";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { LoginContainer } from "./components/LoginContainer";
import { IpAdminApiProvider } from "./api";

const ImportExportPage = React.lazy(() => import("./pages/ImportExportPage"));
const EditPage = React.lazy(() => import("./pages/EditPage"));
const IpCheckPage = React.lazy(() => import("./pages/IpCheckPage"));
const LogoutPage = React.lazy(() => import("./pages/LogoutPage"));

const App: React.FC = () => {
  return (
    <IpAdminApiProvider>
      <LoginContainer require="admin">
        <AppThemeProvider>
          <Router>
            <AppLayout>
              <Switch>
                <Suspense fallback={<div />}>
                  <Route path="/edit">
                    <EditPage />
                  </Route>
                  <Route path="/import-export">
                    <ImportExportPage />
                  </Route>
                  <Route path="/logout">
                    <LogoutPage />
                  </Route>
                  <Route path="/ip-check">
                    <IpCheckPage />
                  </Route>
                  <Route path="/" exact>
                    <Redirect to="/edit" />
                  </Route>
                </Suspense>
              </Switch>
            </AppLayout>
          </Router>
        </AppThemeProvider>
      </LoginContainer>
    </IpAdminApiProvider>
  );
};

export default App;
